import type { WClientUserAccountView } from '@zola/svc-web-api-ts-client';

export const REQUEST_USER = 'zola/user/REQUEST_USER';
export const RECEIVE_USER = 'zola/user/RECEIVE_USER';
export const IDENTIFY_USER = 'zola/user/IDENTIFY_USER';
export const IDENTIFY_GUEST_USER = 'zola/user/IDENTIFY_GUEST_USER';
export const REQUEST_USER_STATUS = 'zola/user/REQUEST_USER_STATUS';
export const RECEIVE_USER_STATUS = 'zola/user/RECEIVE_USER_STATUS';

export type AuthStatusType = 'IDLE' | 'INVALID' | 'EXISTING' | 'ACTIVE';

export interface UserContext extends WClientUserAccountView {
  is_spoof: boolean;
  has_registry: boolean;
  has_wedding_account: boolean;
  has_website: boolean;
  has_guest_list: boolean;
  has_inspiration_account: boolean;
  has_invitation_account: boolean;
  has_marketplace_account: boolean;
  has_completed_onboarding: boolean;
  is_inspiration_vendor: boolean;
  is_inspiration_admin: boolean;
  has_board_items: boolean;
  is_guest: boolean;
}

type RequestUserActionType = { type: typeof REQUEST_USER };

type ReceiveUserActionType = { type: typeof RECEIVE_USER; payload: UserContext };

type RequestUserStatusActionType = { type: typeof REQUEST_USER_STATUS };
type ReceiveUserStatusAction = {
  type: typeof RECEIVE_USER_STATUS;
  payload: { status: AuthStatusType };
};

export type UserActionTypes =
  | RequestUserActionType
  | ReceiveUserActionType
  | RequestUserStatusActionType
  | ReceiveUserStatusAction;
